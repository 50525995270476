import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { maxBy, minBy } from 'lodash';

const moment = extendMoment(Moment);

export const dateFormatForUI = 'Do MMMM YYYY';
export const compactDateFormatForUI = 'Do MMM YYYY';
export const dateFormatForMobileUI = 'D MMM YY';
export const timeFormatForUI = 'HH:mm';
export const dateFormatForAPI = 'YYYY-MM-DD';
export const shortDateFormat = 'DD/MM/YY';
export const dateFormatForSearchUI = 'Do MMM YY';

export const defaultDateFormat = 'DD/MM/yyyy';

export const generateTimeRange = (start, end, isEnd = false) => {
  if (!start || !end) return [];

  // extract hours and minutes integers from start and end time
  const [startHour, startMinute] = start.split(':');
  const [endHour, endMinute] = end.split(':');

  // create some timestaps with those times, we don't care what the date is
  const startTimestamp = moment().set({
    hour: startHour,
    minute: startMinute,
  });
  const endTimestamp = moment().set({
    hour: endHour,
    minute: endMinute,
  });

  // depending on if this is the start or end field, create a date
  // range with appropriate start/end times
  const timeRange = isEnd
    ? moment.range(startTimestamp.add(1, 'hour'), endTimestamp)
    : moment.range(startTimestamp, endTimestamp);

  return Array.from(timeRange.by('minute', { step: 30 })).map(t =>
    t.format('HH:mm')
  );
};

export const generateTimeList = (start, end, isEnd = false) => {
  if (!start || !end) return [];

  const timeRange = generateTimeRange(start, end, isEnd);

  // generate array of select values and return it
  return timeRange.map(t => ({
    value: t,
    label: t,
  }));
};

export const getStartAndEndTimes = (
  openingTime,
  closingTime,
  date = undefined
) => {
  return {
    startTime: moment(date).isSame(moment(), 'day')
      ? moment().startOf('hour').format('HH:mm')
      : openingTime,
    endTime: closingTime,
  };
};

export const formatDate = (date, format = defaultDateFormat) =>
  moment(date).format(format);

export const sortByTime = key => {
  return (a, b) => {
    if (moment(a[key]) < moment(b[key])) {
      return -1;
    }

    if (moment(a[key]) === moment(b[key])) {
      return 0;
    }

    return 1;
  };
};

export const dateInCurrentMonth = date =>
  moment.utc(date).isSame(moment.utc(), 'month');

export const maxDate = (items, key) => {
  if (!items.length) {
    return undefined;
  }

  return new Date(
    maxBy(items, item => {
      return moment.utc(item[key]);
    })[key]
  );
};

export const minDate = (items, key) => {
  if (!items.length) {
    return undefined;
  }

  return new Date(
    minBy(items, item => {
      return moment.utc(item[key]);
    })[key]
  );
};

export const midDate = (items, key) => {
  if (!items.length) {
    return undefined;
  }

  const getTimeFromDate = date => moment.utc(date).toDate().getTime();

  return new Date(
    (getTimeFromDate(maxDate(items, key)) +
      getTimeFromDate(minDate(items, key))) /
      2
  );
};

export const isPastDate = (date: string) =>
  moment(date).endOf('day').isBefore(moment());

export const isThisMonth = (date: string) =>
  moment(date).isBefore(moment().endOf('month'));
