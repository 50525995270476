import NextI18nNext from 'next-i18next';
import config from '../config/i18n';

import { formatDate, dateFormatForUI } from './date';

const env = process.env.NODE_ENV || 'development';
const path = require('path');

const NextI18NextInstance = new NextI18nNext({
  ...config[env],
  otherLanguages: [],
  localePath: path.resolve('./public/static/locales'),
  interpolation: {
    alwaysFormat: true,
    escapeValue: false,
    formatSeparator: ',',
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase();

      if (value instanceof Date)
        return formatDate(value, format || dateFormatForUI);

      return value;
    },
  },
});

export const {
  appWithTranslation,
  withTranslation,
  i18n,
} = NextI18NextInstance;
