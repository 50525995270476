import common from '../../../public/static/locales/en/common.json';

export default {
  defaultLanguage: 'en',
  lng: 'en',
  fallbackLng: 'en',
  otherLanguages: [],
  debug: true,
  resources: {
    en: {
      common,
    },
  },
};
