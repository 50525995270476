import { initReactI18next } from 'react-i18next';
import common from '../../../public/static/locales/en/common.json';

/**
 * Alternate configuration for testing with i18n enabled.
 *
 * This is a bit of a fudge but what we're doing here is forcing i18next to initialise with
 * resources so that they're readily available in a test environment.
 */
export default {
  defaultLanguage: 'en',

  otherLanguages: ['es'],

  lng: 'en',

  fallbackLng: 'en',

  resources: {
    en: {
      common,
    },
  },

  use: [initReactI18next],

  react: {
    wait: false,
  },
};
